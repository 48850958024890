import React, { useState, Fragment } from "react";
import { navigate } from "gatsby";
import { login, getUserProfile } from "../../common/constants/api";
import {
  setToken,
  setRole,
  setTeam,
  setIsApproved,
} from "../../common/utils/auth-utils";
import { setAcl } from "../../common/utils/acl-utils";
import FormikWizard from "formik-wizard";
import steps from "./steps";
import SignIn from "../../components/SignIn/SignIn";
import "./Login.css";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import loginPagePic from "../../images/icons/newLogin/loginPage.png";
import mainPagePic from "../../images/icons/newLogin/mainPage.png";
import anotherImage from "../../images/icons/newLogin/anotherImage.png";
import CommonSlide from "../../common/components/CommonImageSlide/CommonSlide";
import { removeLoginTypeCookies } from "../../common/utils/cookie-utils";
import UpdateButton from "../../common/components/updateButton/UpdateButton";
import { setUserDetailsInfo } from "../../common/utils/userDetailsInfo";

function FormWrapper({ values, goToPreviousStep, wizard }) {
  const [moveNext, setMoveNext] = useState("SignIn");
  const images = [mainPagePic, loginPagePic, anotherImage];
  const title = [
    "Become a Certified Business Partner (BP)",
    "Create Lead & upload Documents in System",
    "Earn money on Booking",
  ];
  return (
    <React.Fragment>
      <UpdateButton
        message="This application has been updated. It will be reloaded soon."
        className={
          moveNext === "SignIn"
            ? `w-full flex flex-row min-h-full h-screen relative overflow-hidden`
            : `h-screen w-full`
        }
      >
        <div
          className={
            moveNext === "SignIn"
              ? `w-full h-screen bg-orange-1400 md:bg-white`
              : `h-screen w-full`
          }
        >
          {moveNext === "SignIn" && (
            <Fragment>
              <div className="flex md:items-center md:justify-center mt-10 px-4">
                <KeyboardBackspaceIcon
                  className="block mt-3 md:mt-0 cursor-pointer "
                  onClick={() => {
                    removeLoginTypeCookies(), navigate("/");
                  }}
                  color="action"
                  style={{ fontSize: 36 }}
                />
                <div className="max-w-full lg:mt-10 mt-3 mb-5 md:mb-2 mx-auto text-center"></div>
              </div>
              <div
                className=" font-bold text-base xxs:text-xl md:text-xl mt-3 mb-5 md:mb-2 mx-auto text-center max-w-full pb-4"
                style={{
                  color: "#394759",
                }}
              >
                Fincity Employee
              </div>
            </Fragment>
          )}
          <div
            className={
              moveNext === "SignIn"
                ? `relative flex flex-row items-center w-full h-full justify-center space-x-3`
                : `h-screen w-full`
            }
          >
            <div
              className={
                moveNext === "SignIn"
                  ? `flex flex-col place-self-start content-center w-full h-full`
                  : `h-screen w-full`
              }
            >
              {wizard.step.id == "otp" ? (
                <OtpPage
                  wizard={wizard}
                  values={values}
                  previousStep={goToPreviousStep}
                />
              ) : (
                <SignIn loginFrom={"RM"} setMoveNext={setMoveNext} />
              )}
            </div>
          </div>
        </div>
        {moveNext === "SignIn" && (
          <CommonSlide
            outerClass="lg:block hidden w-full h-screen"
            imageClass="h-screen relative"
            images={images}
            title={title}
          />
        )}
      </UpdateButton>
    </React.Fragment>
  );
}
const Login = () => {
  const handleSubmit = React.useCallback(values => {
    return regisrerOtp(values.otp, values.signup);
  }, []);
  let regisrerOtp = async (sectionValues, formValues) => {
    const values = {};
    if (formValues.mobile) {
      values.phone = formValues.mobile;
      values.email = formValues.email;
      values.mobileOtp = sectionValues.mobileOtp;
      values.emailOtp = sectionValues.emailOtp;
      try {
        await confirmOtp(values, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        try {
          let res = await login({
            username: formValues.mobile,
            userType: 0,
            password: formValues.password,
          });
          let [id, token, username, name] = [
            res?.id,
            res?.token,
            res?.username,
            res?.name,
          ];
          await setToken({ id, token, username });
          let obj = {
            username: username,
            name: name,
          };
          setUserDetailsInfo(obj);
          setAcl(res?.acl);
          setRole(res?.role);
          setTeam(res?.team);
          setIsApproved(res?.checks?.isApproved);
          let resp = await getUserProfile();
          navigate("/app/dashboard");
        } catch (err) {
          return err.response?.data;
        }
      } catch (err) {
        return err.response?.data;
      }
    }
  };
  return (
    <FormikWizard steps={steps} onSubmit={handleSubmit} render={FormWrapper} />
  );
};
export default Login;
