import React, { useEffect, useState } from "react";

const SvgComponent = ({
  img,
  className,
  style,
  width,
  height,
  viewBoxLeftTopPosition,
  viewBoxLeftBottomPosition,
  invoke = false,
}) => {
  const [svgNode, setSvgNode] = useState({});

  useEffect(() => {
    let a = img?.replace("data:image/svg+xml;base64,", "");
    let e = document?.createElement("div");
    try {
      e.innerHTML = atob(a); // converts the base64 format to string svg
      setSvgNode(e?.children[0]);
    } catch {
      setSvgNode({});
    }
  }, [invoke]);

  let baseVal = svgNode?.viewBox?.baseVal;

  return (
    <svg
      xmlns={svgNode?.namespaceURI || "http://www.w3.org/2000/svg"}
      width={width || svgNode?.width?.baseVal?.valueAsString}
      height={height || svgNode?.height?.baseVal?.valueAsString}
      viewBox={`${viewBoxLeftBottomPosition || baseVal?.x} ${
        viewBoxLeftTopPosition || baseVal?.y
      } ${width || baseVal?.width} ${height || baseVal?.height}`}
      className={`fill-current ${className}`}
      style={style}
      dangerouslySetInnerHTML={{
        __html: svgNode?.innerHTML,
      }}
    ></svg>
  );
};

export default SvgComponent;
