import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import Layout from "../components/Layout/Layout";
import Login from "../containers/Login/Login";
import {
  isLoggedIn,
  getUserType,
  setUserType,
} from "../common/utils/cookie-utils";
import {
  setRole,
  setTeam,
  setProjectHead,
  setAskForPassword,
  setIsApproved,
} from "../common/utils/auth-utils";
import withErrorHandler from "../hoc/withErrorHandler/withErrorHandler";
import Logout from "../common/components/Logout/Logout";
import MainPage from "../common/components/MainPage/MainPage";
import ConsumerMainPage from "../common/components/MainPage/ConsumerMainPage";
import { DeveloperDataContext } from "../common/utils/appContext";
import get from "lodash/get";
import { getAllDeviceType, loginThroughToken } from "../common/constants/api";
import { setToken as loginTokenSetter } from "../common/utils/auth-utils";
import { setAcl } from "../common/utils/acl-utils";
import NewSpinner from "../common/components/NewSpinner/NewSpinner";
import { setUserDetailsInfo } from "../common/utils/userDetailsInfo";

let url;
{
  typeof window !== "undefined" ? (url = new URL(window.location.href)) : "";
}

const IndexPage = () => {
  const isLogged = isLoggedIn();
  const userType = getUserType();
  const [loading, setLoading] = useState(
    url?.searchParams?.get("isLandingPage")
  );
  const [appData, setAppData] = useState({
    developerDetails: {},
    activePages: {},
    campaignDetails: {},
    updateUserProfile: false,
  });
  useEffect(() => {
    let ladingPage = url?.searchParams?.get("isLandingPage");
    localStorage.setItem("ladingPage", ladingPage);
    if (ladingPage) {
      let os = url?.searchParams?.get("device-type");
      let token = url?.searchParams?.get("token");
      getAllDeviceType()
        .then(res =>
          res.map(each => {
            if (each?.name == os) {
              localStorage.setItem("deviceId", each?.id);
            }
          })
        )
        .catch(err => {});
      let body = {
        token: token,
        deviceType:
          localStorage.getItem("deviceId") == undefined
            ? 1
            : JSON.parse(localStorage.getItem("deviceId")),
      };
      loginThroughToken(body)
        .then(async res => {
          let [id, token, username, name, userType] = [
            res?.id,
            res?.token,
            res?.username,
            res?.name,
            res?.userType,
          ];
          localStorage.setItem("loggedInUserName", name);
          loginTokenSetter({ id, token, username, userType });
          setAcl(res?.acl);
          setRole(res?.role);
          let obj = {
            username: username,
            name: name,
          };
          setUserDetailsInfo(obj);
          setTeam(res?.team);
          setProjectHead(res?.checks?.isProjectHead);
          setIsApproved(res?.checks?.isApproved);
          setAskForPassword(false);
          setUserType(userType);
          navigate("/app/Consumer/Properties");
        })
        .catch(err => {});
    }
  }, []);

  if (!isLogged) {
    if (url?.searchParams.get("user") == "consumer") {
      if (loading)
        return (
          <div className="flex justify-center">
            <NewSpinner />
          </div>
        );
      else
        return (
          <Layout customTitle="Consumer | ">
            <DeveloperDataContext.Provider
              value={{
                appData,
                setAppData,
              }}
            >
              <ConsumerMainPage />
            </DeveloperDataContext.Provider>
          </Layout>
        );
    } else {
      return (
        <Layout customTitle="Fincity Employee | ">
          <DeveloperDataContext.Provider
            value={{
              appData,
              setAppData,
            }}
          >
            <MainPage />
          </DeveloperDataContext.Provider>
        </Layout>
      );
    }
  } else if (userType != undefined) {
    if (userType == 1) {
      navigate("/app/dashboard/bp");
    } else if (userType == 3) {
      navigate("/app/Consumer/Properties");
    } else {
      navigate("/app/dashboard");
    }
  } else Logout({ hardReload: false, routeTo: "/" });
  return null;
};
export default IndexPage;
