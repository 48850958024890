import { number, object } from "yup";
import { otpLogin } from "../../constants/api";

const Steps = [
  {
    id: "MainPage",
    initialValues: {
      mobile: "",
      password: "",
    },
  },
  {
    id: "otpPhoneNumber",
    initialValues: {
      mobile: "",
      dialCode: "",
      countryCode: "",
      resMsg: "",
      apiCalled: "",
    },
    onAction: async (sectionValues, formValues) => {
      let body = {
        phoneNumber: sectionValues?.mobile,
      };
      !sectionValues?.apiCalled &&
        otpLogin(body)
          .then(res => {
            sectionValues.resMsg = res;
            sectionValues.apiCalled = true;
          })
          .catch(err => {
            throw new Error(
              err?.response?.data?.message || "Oops! something went wrong !!"
            );
          });
    },
  },
  {
    id: "otp",
    initialValues: {
      mobileOtp: "",
      resMsg: "",
    },
    validationSchema: object().shape({
      mobileOtp: number()
        .typeError("Numbers only")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .min(4, "must be a 4 digit no")
        .required("An otp is required"),
    }),
  },
];

export default Steps;
