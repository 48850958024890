import React from "react";
import styles from "./CommonCheckbox.module.css";
function CommonCheckbox(props) {
  const {
    name,
    value,
    checked,
    onChange,
    onBlur,
    error,
    classProps,
    containerStyles,
    inputStyles,
    errorClass,
    label,
    labelStyle,
    colorStyle,
    exportedStyles,
    checkboxInput,
  } = props;
  const { container, checkmark, checkmarkdiff } = exportedStyles || styles;
  return (
    <div style={containerStyles}>
      <label className={`${container}`}>
        <input
          className={checkboxInput}
          type="checkbox"
          checked={checked}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />{" "}
        <span
          className={
            `${colorStyle ? checkmarkdiff : checkmark} ${classProps} ` +
            (error && `${errorClass}`)
          }
          style={inputStyles}
        ></span>
        <p className={labelStyle}>{label}</p>
      </label>
    </div>
  );
}

export default CommonCheckbox;
