import React, { useState, useEffect, Fragment, useContext } from "react";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import bpIcon from "../../../images/icons/newLogin/businessPartnerIcon.svg";
import developerIcon from "../../../images/icons/newLogin/developerIcon.svg";
import rmIcon from "../../../images/icons/newLogin/fincityEmployeeIcon.svg";
import CommonSlide from "../../../common/components/CommonImageSlide/CommonSlide";
import { Check } from "@material-ui/icons";
import mainPagePic from "../../../images/icons/newLogin/mainPage.png";
import loginPagePic from "../../../images/icons/newLogin/loginPage.png";
import anotherImage from "../../../images/icons/newLogin/anotherImage.png";
import loginPageImgFirst from "../../../images/consumer/ConsumerSignUp1.png";
import loginPageImgSecond from "../../../images/consumer/ConsumerSignUp2.png";
import loginPageImgThird from "../../../images/consumer/ConsumerSignUp3.png";
import useWindowResize from "../../../hooks/useWindowResize";
import { navigate } from "gatsby";
import {
  getLoginTypeCookies,
  isLoggedIn,
} from "../../../common/utils/cookie-utils";
import Layout from "../../../components/Layout/Layout";
import FormikWizard, { useFormikWizard } from "formik-wizard";
import Steps from "./Steps";
import UpdateButton from "../updateButton/UpdateButton";
import { GA_EVENTS, gtagTrackEvent } from "../../constants/GA4";
import SignIn from "../../../components/SignIn/SignIn";
import mainPageIcon from "../../../images/icons/newLogin/mainPageLogo.svg";
import OtpPage from "../../../components/OtpPage/OtpPage";
import GlobalOtp from "../../../common/components/GlobalOtpComp/GlobalOtpComp";
import Login from "../../../containers/BpRegister/CustomerForm/CustomerFormComponent/Login";
import EnterMobNo from "../../../containers/BP/BPOnBoarding/components/EnterMobNo";
import Otp from "../../../common/components/GlobalOtpComp/GlobalOtpComp";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import { getAllDeviceType, login } from "../../constants/api";
import {
  setToken as loginTokenSetter,
  setIsApproved,
} from "../../../common/utils/auth-utils";
import { setAcl } from "../../../common/utils/acl-utils";
import { setRole, setTeam, setProjectHead } from "../../utils/auth-utils";
import { UserDeviceType } from "../../constants/UserDeviceDetails";
import { useFormikContext } from "formik";
import { Tooltip } from "@material-ui/core";
import Toast from "../Toast/Toast";
import { DeveloperDataContext } from "../../utils/appContext";
import { setUserDetailsInfo } from "../../utils/userDetailsInfo";

function FormikWrapper({ wizard }) {
  const [moveNext, setMoveNext] = useState("SignIn");
  const [windowWidth, windowHeight] = useWindowResize();
  const [showFP, setShowFP] = useState(false);
  const images = [loginPageImgFirst, loginPageImgSecond, loginPageImgThird];
  const isLogged = isLoggedIn();
  const title = [
    "Home is where the heart is.",
    "Make your Dream Home Reality!",
    "Its not a property , its a business opportunity",
  ];
  const [deviceType, setDeviceType] = useState("");
  const [allDevices, setAllDevices] = useState([]);
  const [os, setOS] = useState("");
  const [device, setDevice] = useState(2);

  const { setFieldValue } = useFormikContext();

  let url = "";
  if (typeof window != "undefined") {
    url = new URL(window.location.href);
  }
  let id = url?.searchParams?.get("deviceId")
    ? url.searchParams.get("deviceId")
    : localStorage.getItem("deviceId");
  const [deviceId, setDeviceId] = useState(id);

  useEffect(() => {
    setDeviceType(UserDeviceType());

    getAllDeviceType()
      .then(res => setAllDevices(res))
      .catch(err => {});
  }, []);

  useEffect(() => {
    if (
      deviceType == "iPhone" ||
      deviceType == "iPad" ||
      deviceType == "iPod"
    ) {
      setOS("Ios");
    } else if (deviceType == "Android") {
      setOS("Android");
    } else if (deviceType == "macOS") {
      setOS("Osx");
    } else {
      setOS("Windows");
    }
  }, [deviceType, allDevices, deviceId]);

  useEffect(() => {
    allDevices.map(each => {
      if (each?.name == os) {
        setDevice(each?.id);
        setFieldValue("device", each?.id);
        localStorage.setItem("deviceId", each?.id);
      }
    });
  }, [os, allDevices]);

  if (!isLogged)
    return (
      <Layout customTitle="Welcome | Fincity " bgColor="bg-white">
        <UpdateButton
          message="This application has been updated. It will be reloaded soon."
          className={`lg:flex lg:justify-between h-screen w-full md:overflow-none overflow-hidden`}
        >
          <div
            className={`w-full h-screen flex md:items-center items-start ${
              windowHeight < 660
                ? "overflow-auto"
                : windowHeight < 690 && windowWidth < 300
                ? "overflow-auto"
                : ""
            }`}
          >
            <div className={`mx-auto ${windowWidth < 767 ? "w-full" : "w-89"}`}>
              <div className={` ${windowWidth > 767 ? `` : `w-full`}`}>
                {
                  // moveNext === "SignIn" &&
                  <Fragment>
                    <div
                      className="px-13 text-base xxs:text-xl md:text-xl mb-5 md:mb-2 mx-auto text-left max-w-full pt-16"
                      style={{
                        color: "#394759",
                      }}
                    >
                      <img src={mainPageIcon} alt="fincity" />
                      <div className="mt-2 md:mt-6 font-normal text-lg ">
                        {wizard.step.id == "otp"
                          ? "Enter the OTP you received"
                          : moveNext == "ForgotPassword"
                          ? ""
                          : "Login to Your Account"}
                      </div>
                    </div>
                  </Fragment>
                }
                <div
                  className={`relative flex flex-row items-center w-full h-full justify-center space-x-3`}
                >
                  <div className={`flex flex-col content-center w-full h-full`}>
                    {wizard.step.id == "otpPhoneNumber" ? (
                      <EnterMobNo lizard={wizard} />
                    ) : wizard.step.id == "otp" ? (
                      <GlobalOtp lizard={wizard} />
                    ) : (
                      <SignIn
                        loginFrom={"Consumer"}
                        setMoveNext={setMoveNext}
                        showFP={showFP}
                        setShowFP={setShowFP}
                        lizard={wizard}
                      />
                    )}
                  </div>
                </div>
              </div>
              {windowWidth > 767 &&
                wizard.step.id == "MainPage" &&
                moveNext == "SignIn" && (
                  <div className="px-7">
                    <button
                      onClick={() => navigate("/consumer")}
                      className="submitButton newSignUp w-11/12 h-13"
                      type="submit"
                    >
                      New to Fincity ?<span className="px-1">Sign Up</span>
                    </button>
                  </div>
                )}
            </div>
          </div>
          <div className="w-full h-screen bgImage md:block hidden"></div>
        </UpdateButton>
      </Layout>
    );
  else return null;
}

const CommonLogin = () => {
  const Context = useContext(DeveloperDataContext);
  const handleSubmit = React.useCallback(async values => {
    await Submit(values?.otp, values);
  });

  let Submit = (sectionValues, formValues) => {
    Context.setAppData({
      ...Context.appData,
      waitForRequest: true,
    });
    let loginBody = {
      phoneNumber: formValues?.otpPhoneNumber?.mobile,
      otpLogin: { otpLogin: true, otp: sectionValues?.mobileOtp },
      deviceType: localStorage.getItem("deviceId"),
    };
    login(loginBody)
      .then(res => {
        Context.setAppData({
          ...Context.appData,
          waitForRequest: false,
        });
        let [id, token, username, userType, name] = [
          res?.id,
          res?.token,
          res?.username,
          res?.userType,
          res?.name,
        ];
        let obj = {
          username: username,
          name: name,
        };
        setUserDetailsInfo(obj);
        loginTokenSetter({ id, token, username, userType });
        setAcl(res?.acl);
        setRole(res?.role);
        setTeam(res?.team);
        setIsApproved(res?.checks?.isApproved);
        if (userType == 1) {
          gtagTrackEvent(GA_EVENTS.LOGIN_CLICK_BP);
          navigate("/app/dashboard/bp");
        } else if (userType == 3) {
          gtagTrackEvent(GA_EVENTS.LOGIN_CLICK_CONSUMER);
          navigate("/app/Consumer/Properties");
        } else {
          gtagTrackEvent(GA_EVENTS.LOGIN_CLICK_RM);
          navigate("/app/dashboard");
        }
        if (Context?.appData?.loginOtpError) {
          delete Context.appData.loginOtpError;
        }
      })
      .catch(err => {
        if (err.response !== undefined) {
          Context.setAppData({
            ...Context.appData,
            loginOtpError: err?.response?.data?.message,
          });
        }
      });
  };
  return (
    <FormikWizard
      onSubmit={handleSubmit}
      steps={Steps}
      render={FormikWrapper}
    />
  );
};

export default CommonLogin;
